export default {
  methods: {
    getPhotoUser(url) {
      if (url) return url;
      return require('@/assets/photo1.png');
    },
    getPhotoNutritionist(url) {
      if (url) return url;
      return require('@/assets/photo.png');
    },
    getPhotoProgram(url) {
      if (url) return url;
      return require('@/assets/img-program.png');
    },
    formatBytes(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
      return Math.round((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    getYoutubeId(url) {
      if (!url) return '';
      let watch = url.split("/watch?v=");
      let slash = url.split("/");
      if (url.includes("watch") && watch.length > 0) {
        return watch[watch.length - 1];
      } else {
        return slash[slash.length - 1];
      }
    },
    trimText(value, length = 18) {
      if (value.length > length) {
        return `${value.substring(0, length)}...`;
      }
      return value;
    },
  },
};

<template>
  <c-flex h="64px" p="17px" align-items="center" border-bottom="1px solid #f2f2f2">
    <c-link as="router-link" :to="to"
        w="100%"
        display="flex"
        align-items="center"
        justify-content="space-between"
    >
      <c-text>{{ title }}</c-text>
      <c-image :src="require('@/assets/icon-chevron-right.svg')" alt="icon arrow right" />
    </c-link>
  </c-flex>
</template>

<script>
export default {
  name: 'menu-item',
  props: ['title', 'to']
}
</script>

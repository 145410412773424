<template>
  <c-flex
    flex-direction="column"
    bg="white"
    border="1px solid #f2f2f2"
    mx="auto"
    box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
    border-radius="6px"
    w="100%"
  >
    <c-flex border-bottom="1px solid #f2f2f2" p="17px">
      <c-image
        :src="getPhotoUser(profile.avatarUrl)"
        :alt="fullName"
        w="64px"
        h="64px"
        rounded="full"
        object-fit="cover"
      />
      <c-flex flex-direction="column" justify="center" ml="21px">
        <c-text as="h2" color="#111" font-weight="600">
          {{ fullName }}
        </c-text>
        <c-text mt="3px" color="#888" font-size="12px">
          {{ profile.dataProfile.email }}
        </c-text>
      </c-flex>
    </c-flex>
    <menu-item title="Lihat Profile" :to="{ name: 'client.profile.detail' }" />
    <menu-item title="Pengaturan" :to="{ name: 'client.profile.settings' }" />
    <c-flex as="link" @click="logout" h="64px" p="17px" align-items="center">
      <c-text color="red.900">Logout</c-text>
    </c-flex>
  </c-flex>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import MenuItem from "./menu-item.vue";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "ProfilePage",
  components: {
    MenuItem,
  },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      profile: (s) => s.profile,
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    ...mapGetters({
      fullName: "profile/fullName",
    }),
  },
  methods: {
    ...mapActions({
      // client
      getProfileClient: "profile/getProfileClient",
      getProfile: "profile/getProfile",
      signout: "auth/signout",
    }),
    logout() {
      this.signout()
        .then(() => {
          this.$router.replace("/");
          if (this.$route.name.includes("admin")) {
            this.$router.replace("/login-empty");
          }
        })
        .catch(() => {
          this.$router.replace("/");
        });
    },
  },
  mounted() {
    if (this.role === "client") {
      this.getProfileClient();
    }
    if (this.role === "nutritionist") {
      this.getProfile();
    }
  },
};
</script>
